<template>
  <div class="o-map">
    <l-map
      ref="map"
      :options="mapOptions"
      gestureHandling
      @ready="onMapReady"
      @update:center="setCenter"
      @update:zoom="setZoom"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
        <template
          v-for="item of shops"
        >
          <l-marker
            :key="`shop-${item.id}`"
            :lat-lng="{ lon: item.long, lat: item.lat }"
            :icon="icon"
            @click="selectShop(item)"
          >
          </l-marker>
      </template>
    </l-map>

    <div class="o-map__zoom">
      <button
        class="o-map__zoom-in"
        :disabled="zoom === mapOptions.maxZoom"
        @click="zoomIn"
      ></button>
      <button
        class="o-map__zoom-out"
        :disabled="zoom === mapOptions.minZoom"
        @click="zoomOut"
      ></button>
    </div>
  </div>
</template>
<script>
import DeviceType from 'theme/mixins/DeviceType';
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet';
import { icon, latLng } from 'leaflet';
import '@raruto/leaflet-gesture-handling';
import { isMac } from '@vue-storefront/core/helpers';

const DEFAULT_ZOOM = 11

export default {
  name: 'OMap',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  props: {
    city: {
      type: Object,
      default: () => ({})
    },
    shops: {
      type: Array,
      default: () => []
    },
    pageType: {
      type: String,
      required: true
    }
  },
  mixins: [DeviceType],
  data () {
    return {
      map: null,
      zoom: DEFAULT_ZOOM,
      center: latLng(50.450001, 30.523333),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        scrollWheelZoom: 'center',
        doubleClickZoom: 'center',
        minZoom: 2,
        maxZoom: 18,
        boxZoom: false,
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.5,
        gestureHandling: true,
        gestureHandlingOptions: {
          text: {
            touch: this.$t('Two finger move map'),
            scroll: isMac ? this.$t('Mac scroll map') : this.$t('Text scroll map'),
            scrollMac: isMac ? this.$t('Mac scroll map') : this.$t('Text scroll map')
          }
        }
      },
      icon: icon({
        iconUrl: '/assets/map-pin-varus.svg',
        iconSize: [29, 34],
        iconAnchor: [13, 34]
      }),
      buffer: null
    }
  },
  watch: {
    city: {
      immediate: true,
      handler: function (newVal) {
        this.setCenterAndZoom(newVal?.coordinates?.lat, newVal?.coordinates?.lng, DEFAULT_ZOOM)
      }
    }
  },
  methods: {
    onMapReady () {
      this.map = this.$refs.map.mapObject

      if (!this.buffer) return

      this.setCenterAndZoom(this.buffer?.lat, this.buffer?.lng, this.buffer?.zoom)
    },
    setCenter (center) {
      this.center = center
    },
    setZoom (zoom) {
      this.zoom = zoom
    },
    openInfoWindow (location) {
      this.map.selectedLocation = location
    },
    zoomIn () {
      return this.map?.zoomIn()
    },
    zoomOut () {
      return this.map?.zoomOut()
    },
    setCenterAndZoom (lat, lng, zoom) {
      const currentZoom = lat && lng ? zoom || this.zoom : 6

      if (!this.map?.setView) {
        this.buffer = {
          lat: lat,
          lng: lng,
          zoom: currentZoom
        }

        return
      }
      const currentCenter = lat && lng ? latLng(+lat, +lng) : this.center

      this.zoom = currentZoom

      this.map.setView(currentCenter, currentZoom, { animation: true })
    },
    selectShop (item) {
      this.$emit('open-info', item)
      this.setCenterAndZoom(item?.lat, item?.long)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '../../../../modules/shipping/styles/leaflet.css';
@import "~@raruto/leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

@import '~theme/css/fonts';

.o-map {
  height: 35.5625rem;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: 0;

  .o-map__zoom {
    border: none;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    position: absolute;
    z-index: 9990;
    right: 24px;
    bottom: 24px;
    display: flex;
    flex-direction: column;
  }

  ::v-deep {
    .o-map__zoom-in,
    .o-map__zoom-out,
    .o-map__locate-button {
      transition: opacity 200ms;
      width: 48px;
      height: 48px;
      border: none;
      position: relative;
      content: ' ';
      color: transparent;
      background: var(--white);

      &:disabled {
        opacity: .5;
        pointer-events: none;
        background: none;
      }

      &:before {
        transition: color 200ms;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover:before {
        color: var(--orange)
      }
    }

    .o-map__zoom-in {
      @include font-icon(var(--icon-plus));

      &:before {
        color: var(--black);
        font-size: 15px;
      }
    }

    .o-map__zoom-out {
      @include font-icon(var(--icon-minus));

      &:before {
        color: var(--black);
        font-size: 15px;
      }
    }
  }
}
</style>
